var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('CrudTable',{attrs:{"tabelaKey":"id","tabelaComDetalhes":true,"headers":_vm.headers,"items":_vm.associados,"pagination":_vm.pagination,"loading":_vm.loading},on:{"actualPage":_vm.page,"clickRow":function($event){return _vm.detalharAssociado($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"value":_vm.filtros,"max-width":"720px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('div',[_c('ButtonMain',{staticClass:"primary mr-3",attrs:{"icone":"arrow-down-bold","text":_vm.baixandoRelatorio ?  'Baixando Planilha...' : 'Baixar Planilha',"animacao":_vm.baixandoRelatorio ? 'download' : '',"overflowHidden":_vm.baixandoRelatorio ? true : false,"desabilitar":_vm.baixandoRelatorio ? true : false},nativeOn:{"click":function($event){return _vm.baixarPlanilha()}}}),_c('ButtonMain',{staticClass:"primary",attrs:{"text":"filtrar"},nativeOn:{"click":function($event){return _vm.abrirDialogFiltro()}}}),(_vm.filtroAplicado)?_c('ButtonMain',{staticClass:"ml-2 warning",attrs:{"text":"Limpar filtros"},nativeOn:{"click":function($event){_vm.limparFiltro()
                  _vm.filtroAplicado = false}}}):_vm._e()],1)]}}])},[_c('v-card',{staticClass:"overflow-hidden"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"font-weight-light"},[_vm._v(" Aplicar filtros ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","label":"Filtrar pelo nome"},model:{value:(_vm.filtro.nome),callback:function ($$v) {_vm.$set(_vm.filtro, "nome", $$v)},expression:"filtro.nome"}})],1),_c('InputMaskField',{attrs:{"label":"Filtrar pelo CPF","maskFormat":"###.###.###-##","valueField":_vm.filtro.cpf,"cols":6},on:{"update:value":function($event){_vm.filtro.cpf = $event}}}),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","label":"Filtrar pelo numero da OAB"},model:{value:(_vm.filtro.numeroOab),callback:function ($$v) {_vm.$set(_vm.filtro, "numeroOab", $$v)},expression:"filtro.numeroOab"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"clearable":"","hide-details":"auto","outlined":"","items":[
                      {
                        text: 'Nao atualizado',
                        value: 1
                      },
                      {
                        text: 'Atualizado',
                        value: 2
                      }
                    ],"label":"Filtrar pelo Situação"},model:{value:(_vm.filtro.atualizado),callback:function ($$v) {_vm.$set(_vm.filtro, "atualizado", $$v)},expression:"filtro.atualizado"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"clearable":"","hide-details":"auto","outlined":"","items":[
                      {
                        text: 'Irregular',
                        value: 4
                      },
                      {
                        text: 'Regular',
                        value: 2
                      },
                      {
                        text: 'Em análise',
                        value: 1
                      }
                    ],"label":"Filtrar pelo Status"},model:{value:(_vm.filtro.status),callback:function ($$v) {_vm.$set(_vm.filtro, "status", $$v)},expression:"filtro.status"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.limparFiltro()
                  _vm.filtroAplicado = false
                  _vm.filtros = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.aplicarFiltro()}}},[_vm._v("filtrar")])],1)],1)],1)],1)]},proxy:true},{key:"item.cpf",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm._f("VMask")(item.cpf,'###.###.###-##'))+" ")])]}},{key:"item.atualizado",fn:function(ref){
                  var item = ref.item;
return [(item.atualizado)?_c('span',[_vm._v("CADASTRO ATUALIZADO")]):_c('span',[_vm._v("CADASTRO NÃO ATUALIZADO")])]}},{key:"item.idStatus",fn:function(ref){
                  var item = ref.item;
return [_c('StatusChip',{attrs:{"color":_vm.getColor(item.idStatus)}},[_vm._v(" "+_vm._s(item.descricaoStatus)+" ")])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"green","disabled":item.idStatus == 2 ? true : !item.atualizado}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,true)},[_c('span',[_vm._v("Tornar Regular")])])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{attrs:{"color":"primary"}},[_vm._v(" Tem certeza que deseja atualizar o status ? ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-5 body-1 my-6"},[_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(("Essa ação mudará o status do associado " + (item.nome) + " para REGULAR, isso permitirá seu acesso ao sistema. Deseja continuar? "))+" ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.putStatus(item.id, 2)
                  dialog.value = false}}},[_vm._v("Confirmar")])],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"small":"","color":"red","disabled":item.idStatus == 4 ? true : !item.atualizado}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_c('span',[_vm._v("Tornar Irregularizar")])])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{attrs:{"color":"primary"}},[_vm._v(" Tem certeza que deseja atualizar o status ? ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-5 body-1 my-6"},[_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(("Essa ação mudará o status do associado " + (item.nome) + " para IRREGULAR, isso impedirá seu acesso ao sistema. Deseja continuar?"))+" ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.putStatus(item.id, 4)
                  dialog.value = false}}},[_vm._v("Confirmar")])],1)],1)]}}],null,true)})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container>
    <CrudTable
      tabelaKey="id"
      :tabelaComDetalhes="true"
      :headers="headers"
      :items="associados"
      :pagination="pagination"
      :loading="loading"
      @actualPage="page"
      @clickRow="detalharAssociado($event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog :value="filtros" max-width="720px" persistent>
            <template v-slot:activator="{}">
              <div>
                <ButtonMain
                  @click.native="baixarPlanilha()"
                  class="primary mr-3"
                  icone="arrow-down-bold"
                  :text="baixandoRelatorio ?  'Baixando Planilha...' : 'Baixar Planilha'"
                  :animacao="baixandoRelatorio ? 'download' : ''"
                  :overflowHidden="baixandoRelatorio ? true : false"
                  :desabilitar="baixandoRelatorio ? true : false"
                ></ButtonMain>

                <ButtonMain
                  @click.native="abrirDialogFiltro()"
                  text="filtrar"
                  class="primary"
                ></ButtonMain>
                <ButtonMain
                  v-if="filtroAplicado"
                  text="Limpar filtros"
                  class="ml-2 warning"
                  @click.native="
                    limparFiltro()
                    filtroAplicado = false
                  "
                ></ButtonMain>
              </div>
            </template>
            <v-card class="overflow-hidden">
              <v-toolbar flat>
                <v-toolbar-title class="font-weight-light">
                  Aplicar filtros
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      hide-details="auto"
                      outlined
                      label="Filtrar pelo nome"
                      v-model="filtro.nome"
                    ></v-text-field>
                  </v-col>

                  <InputMaskField
                    label="Filtrar pelo CPF"
                    maskFormat="###.###.###-##"
                    @update:value="filtro.cpf = $event"
                    :valueField="filtro.cpf"
                    :cols="6"
                  />

                  <v-col cols="12" md="6">
                    <v-text-field
                      hide-details="auto"
                      outlined
                      label="Filtrar pelo numero da OAB"
                      v-model="filtro.numeroOab"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      clearable
                      hide-details="auto"
                      outlined
                      v-model="filtro.atualizado"
                      :items="[
                        {
                          text: 'Nao atualizado',
                          value: 1
                        },
                        {
                          text: 'Atualizado',
                          value: 2
                        }
                      ]"
                      label="Filtrar pelo Situação"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      clearable
                      hide-details="auto"
                      outlined
                      v-model="filtro.status"
                      :items="[
                        {
                          text: 'Irregular',
                          value: 4
                        },
                        {
                          text: 'Regular',
                          value: 2
                        },
                        {
                          text: 'Em análise',
                          value: 1
                        }
                      ]"
                      label="Filtrar pelo Status"
                    ></v-autocomplete
                  ></v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="red darken-1"
                  text
                  @click="
                    limparFiltro()
                    filtroAplicado = false
                    filtros = false
                  "
                  >Cancelar</v-btn
                >

                <v-btn
                  color="blue darken-1"
                  text
                  @click="aplicarFiltro()"
                >filtrar</v-btn

              ></v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.cpf`]="{ item }">
        <span style="white-space: nowrap;">
          {{ item.cpf | VMask('###.###.###-##') }}
        </span>
      </template>

      <template v-slot:[`item.atualizado`]="{ item }">
        <span v-if="item.atualizado">CADASTRO ATUALIZADO</span>
        <span v-else>CADASTRO NÃO ATUALIZADO</span>
      </template>
      <template v-slot:[`item.idStatus`]="{ item }">
        <StatusChip :color="getColor(item.idStatus)">
          {{ item.descricaoStatus }}
        </StatusChip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-dialog width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="green"
              class="mr-2"
              :disabled="item.idStatus == 2 ? true : !item.atualizado"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small color="white" v-bind="attrs" v-on="on">
                    mdi-check-circle
                  </v-icon>
                </template>
                <span>Tornar Regular</span>
              </v-tooltip>
            </v-btn>
          </template>

          <template v-slot:default="dialog">
            <v-card>
              <v-card-title color="primary">
                Tem certeza que deseja atualizar o status ?
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text class="text-5 body-1 my-6">
                <div class="text--primary">
                  {{
                    `Essa ação mudará o status do associado ${item.nome} para
                 REGULAR,  isso permitirá seu acesso ao sistema. Deseja continuar? `
                  }}
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="red darken-1" text @click="dialog.value = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    putStatus(item.id, 2)
                    dialog.value = false
                  "
                  >Confirmar</v-btn
                >
              </v-card-actions>
            </v-card>
          </template></v-dialog
        >

        <v-dialog width="600"
          ><template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="red"
              class="white--text"
              :disabled="item.idStatus == 4 ? true : !item.atualizado"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small color="white" v-bind="attrs" v-on="on">
                    mdi-close-circle
                  </v-icon>
                </template>
                <span>Tornar Irregularizar</span>
              </v-tooltip>
            </v-btn>
          </template>

          <template v-slot:default="dialog">
            <v-card>
              <v-card-title color="primary">
                Tem certeza que deseja atualizar o status ?
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text class="text-5 body-1 my-6">
                <div class="text--primary">
                  {{
                    `Essa ação mudará o status do associado ${item.nome} para
                  IRREGULAR, isso impedirá seu acesso ao sistema. Deseja continuar?`
                  }}
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialog.value = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    putStatus(item.id, 4)
                    dialog.value = false
                  "
                  >Confirmar</v-btn
                >
              </v-card-actions>
            </v-card>
          </template></v-dialog
        >
      </template>
    </CrudTable>
  </v-container>
</template>

<script>
import api from '@/services/api'
import associadoService from '@/services/AssociadoService'
import globalMethods from '../../mixins/globalMethods.vue'

import TitleH2 from '@/components/atoms/title/TitleH2'
import CrudTable from '@/components/molecules/tables/CrudTable'
import StatusChip from '@/components/atoms/StatusChip.vue'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import ButtonIcon from '@/components/atoms/buttons/ButtonIcon.vue'
import InputMaskField from '@/components/atoms/fields/InputMaskField.vue'

export default {
  components: {
    TitleH2,
    CrudTable,
    StatusChip,
    ButtonMain,
    InputMaskField,
    ButtonIcon
  },
  data() {
    return {
      baixandoRelatorio: false,
      filtroAplicado: false,
      filtros: false,
      loading: false,
      filtro: {
        nome: '',
        cpf: '',
        status: '',
        numeroOab: '',
        atualizado: ''
      },
      associados: [],
      headers: [
        { text: 'Nome', value: 'nome', width: 350, sortable: false },
        { text: 'CPF', align: 'center', value: 'cpf', sortable: false },
        {
          text: 'OAB',
          align: 'center',
          value: 'numeroOab',
          width: 200,
          sortable: false
        },
        { text: 'Situação', value: 'atualizado', sortable: false },
        { text: 'STATUS', align: 'center', value: 'idStatus', sortable: false },
        { text: '', value: 'actions', width: 150, sortable: false }
      ],
      pagination: {
        page: 0,
        perPage: 10,
        total: 0
      }
    }
  },

  mixins: [ globalMethods ],

  methods: {
    detalharAssociado(item) {
      this.$router.push({ name: 'DetalheAssociado', params: { id: item.id } })
    },

    abrirDialogFiltro() {
      this.filtro = {}
      this.filtros = true
      this.filtroAplicado = true
    },

    baixarPlanilha() {
      this.baixandoRelatorio = true;

      associadoService.gerarRelatorio()
        .then(({ data }) => {
          const link = document.createElement('a');
          const horarioAtual = this.horaAtual('America/Sao_Paulo', 'dd-MM-yyyy_HH-mm-ss')

          link.href = encodeURI(`data:text/csv;charset=utf-8,${data}`);
          link.download = `PLANILHA_ASSOCIADOS_${horarioAtual}.csv`;
          link.click();

          this.baixandoRelatorio = false;
        })
        .catch(() => {
          this.baixandoRelatorio = false;
        })
    },

    async limparFiltro() {
      this.loading = true
      this.filtro = {
        nome: '',
        cpf: '',
        status: '',
        numeroOab: '',
        atualizado: ''
      }
      await api.getAssociado(this.pagination).then(({ data }) => {
        this.associados = data.content
        this.pagination.total = data.totalPages
      })
      this.loading = false
    },
    async putStatus(id, status) {
      this.loading = true
      await api
        .putstatusAssociado(id, {
          status: status
        })
        .then((_) => {
          this.$root.vtoast.show({
            status: 'success',
            text: 'Status atualizado.',
            title: 'SUCESSO!'
          })
          if (!this.filtroAplicado) {
            api.getAssociado(this.pagination).then(({ data }) => {
              this.associados = data.content
              this.pagination.total = data.totalPages
            })
          } else {
            this.aplicarFiltro()
          }
        })

      this.loading = false
    },

    aplicarFiltro() {
      const paginacao = { page: 0, perPage: 10, total: 0 };
      this.loading = true;
      this.filtros = false;
      this.loading = false;

      api.getAssociadoFiltro(paginacao, this.filtro)
        .then(({ data }) => {
          this.associados = data.content;
          this.pagination.total = data.totalPages;
        });
    },

    getColor(status) {
      if (status === 4) return 'red';
      if (status === 1) return 'orange';
      if (status === 2) return 'green';
      return '';
    },

    async page(page) {
      this.loading = true
      this.pagination.page = page - 1
      if (this.filtroAplicado) {
        await api
          .getAssociadoFiltro(this.pagination, this.filtro)
          .then(({ data }) => {
            this.associados = data.content
            this.pagination.total = data.totalPages
          })
      }
      if (!this.filtroAplicado) {
        await api.getAssociado(this.pagination).then(({ data }) => {
          this.associados = data.content
          this.pagination.total = data.totalPages
        })
      }
      this.loading = false
    }
  },

  created() {
    this.loading = true;
    api.getAssociado(this.pagination).then(({ data }) => {
      this.associados = data.content
      this.pagination.total = data.totalPages
    })
    this.loading = false;
  }
}
</script>

<style></style>
